<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$EntitiesName.SheetTemplate"
        :create="openCreateModal"
      >
        <h1 class="title">Modèles de Fiche Affaire</h1>
      </page-header>

      <datatable
        ref="SheetTemplateList"
        :fetch="$Api.SheetTemplate.fetchSheetTemplates"
        :enableDownload="true"
        :entity="$EntitiesName.SheetTemplate"
      >
        <div slot="name" slot-scope="{ item }" title="Nom" :sortable="true">{{ item.name }}</div>
        <div slot="project" slot-scope="{ item }" title="Projet" :sortable="true" sortBy="project.name"><span v-if="item.project">{{ item.project.name }}</span></div>
      </datatable>
    </div>

    <modal
      ref="createSheetTemplateModal"
      @create="createSheetTemplate"
      title="Création d'un modèle de fiche affaire"
    >
      <div class="columns">
        <div class="column is-half">
          <ValidationObserver ref="form">
          <text-field label="Nom" data-test="sel-sheettemplate-name" v-model="sheetTemplate.name" :inline="false" :edit="true" required />
          <many2one-field
            label="Projet"
            v-model="sheetTemplate.project"
            :fetch="$Api.Project.fetchProjects"
            reference="name"
            entity="project"
            :columns="projectsColumns"
            :hasFilter="true"
            :inline="false"
            :edit="true"
            required
          ></many2one-field>
          </ValidationObserver>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'sheetTemplates-list',
  data() {
    return {
      sheetTemplate :{},
      columns: {
        name: {
          title: 'Nom',
        },
        project: {
          title: 'Projet',
        },
        controlType: {
          title: 'Control Type',
        },
      },
      projectsColumns: {
        name: 'Projet',
        'region.name': 'Région',
        'region.direction.name': 'Direction'
      }
    };
  },
    methods: {
    openCreateModal() {
      this.$refs.createSheetTemplateModal.open();
    },
    createSheetTemplate() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        axios.post('/sheet-templates', this.sheetTemplate).then((response) => {
          if (response.data.success) {
            this.sheetTemplate = {
              id: '',
              name: '',
              project: '',
              controlType: '',
            };
            this.$refs.SheetTemplateList.refresh();
            this.$refs.createSheetTemplateModal.close();
            this.$router.push({
              name: 'SheetTemplate',
              params: { id: response.data.id },
            });
          }
        });
      })
    },
  },

};
</script>

<style scoped>
.infos {
  display: inline-block;
}

.infos {
  width: 34%;
}
</style>